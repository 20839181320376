<template>
  <div>
    <v-layout v-for="(item, i) in reservationData" :key="i" wrap>
      <v-flex xs12 md12 pt-4>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="item.slotDetail.slot.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.slotDetail.slot.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                    src="require('../../assets/images/no-image.png')"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ item.slotDetail.slot.programme.progName }}
                      </span>
                    </v-flex>
                    <v-flex xs12 md6 text-left py-2 v-if="item.addedBy">
                      <span class="itemCaption" style="font-size: 16px"
                        >Added By :
                      </span>
                      <span class="itemCaption" style="font-size: 16px">
                        {{ item.addedBy.email }}
                        <span v-if="item.addedBy.details">
                          <span v-if="item.addedBy.details.name">
                            ({{ item.addedBy.details.name }})
                          </span>
                        </span>
                      </span>
                    </v-flex>
                    <v-flex xs12 md6 text-left py-2 v-if="item.addedBy">
                      <span class="itemCaption" style="font-size: 16px"
                        >Phone :
                      </span>
                      <span class="itemCaption" style="font-size: 16px">
                        {{ item.addedBy.phone }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.bookingDate) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left>
                      <span class="textField2"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(item.slotDetail.startTime) }} -
                        {{ formatTime(item.slotDetail.endTime) }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left>
                      <span
                        v-if="item.slotDetail.slot.programme.isCottage"
                        class="textField2"
                      >
                        Rooms Reserved</span
                      >
                      <span v-else class="textField2"> Seats Reserved</span
                      ><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.reserved }}
                      </span>
                    </v-flex>

                    <v-flex md3 text-left v-if="item.guestName">
                      <span class="textField2"> Reserved for</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.guestName }}
                      </span>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap justify-start>
                    <v-spacer></v-spacer>
                    <v-flex xs12 sm6 lg4 align-self-end text-right>
                      <v-dialog
                        v-model="item.editdialog"
                        :max-width="
                          $vuetify.breakpoint.name == 'lg' ||
                          $vuetify.breakpoint.name == 'xl'
                            ? '75%'
                            : '90%'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="warning"
                            style="font-family: poppinsmedium"
                            small
                            title="Edit"
                            text
                            v-on="on"
                            v-bind="attrs"
                            class="text-capitalize"
                            @click="editingitem = item"
                          >
                            <v-icon small>mdi-pencil</v-icon> Edit
                          </v-btn>
                        </template>
                        <v-card v-if="editingitem">
                          <v-card-title
                            style="font-family:poppinsregular;font-size:13px"
                          >
                            <v-layout wrap justify-center>
                              <v-flex xs4 lg2 align-self-start pt-2>
                                <v-img
                                  v-if="
                                    item.slotDetail.slot.programme.photos
                                      .length > 0
                                  "
                                  contain
                                  height="110px"
                                  :src="
                                    mediaURL +
                                      item.slotDetail.slot.programme.photos[0]
                                  "
                                >
                                  <template v-slot:placeholder>
                                    <ImageLoader />
                                  </template>
                                </v-img>
                                <v-img
                                  v-else
                                  height="113px"
                                  src="require('../../assets/images/no-image.png')"
                                >
                                  <template v-slot:placeholder>
                                    <ImageLoader />
                                  </template>
                                </v-img>
                              </v-flex>
                              <v-flex xs8 lg10 px-2 align-self-start>
                                <v-layout wrap justify-start>
                                  <v-flex xs12 text-left>
                                    <span
                                      class="itemCaption"
                                      style="font-size: 18px"
                                    >
                                      {{
                                        item.slotDetail.slot.programme.progName
                                      }}
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    md6
                                    text-left
                                    py-2
                                    v-if="item.addedBy"
                                  >
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                      >Added By :
                                    </span>
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                    >
                                      {{ item.addedBy.email }}
                                      <span v-if="item.addedBy.details">
                                        <span v-if="item.addedBy.details.name">
                                          ({{ item.addedBy.details.name }})
                                        </span>
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    md6
                                    text-left
                                    py-2
                                    v-if="item.addedBy"
                                  >
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                      >Phone :
                                    </span>
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                    >
                                      {{ item.addedBy.phone }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap py-3>
                                  <v-flex md3 text-left>
                                    <span class="textField2">
                                      Selected Date</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ formatDate(item.bookingDate) }}
                                    </span>
                                  </v-flex>

                                  <v-flex md3 text-left>
                                    <span class="textField2">
                                      Selected Slot</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{
                                        formatTime(item.slotDetail.startTime)
                                      }}
                                      -
                                      {{ formatTime(item.slotDetail.endTime) }}
                                    </span>
                                  </v-flex>

                                  <v-flex md3 text-left>
                                    <span
                                      v-if="
                                        item.slotDetail.slot.programme.isCottage
                                      "
                                      class="textField2"
                                    >
                                      Rooms Reserved</span
                                    >
                                    <span v-else class="textField2">
                                      Seats Reserved</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.reserved }}
                                    </span>
                                  </v-flex>

                                  <v-flex xs12 text-left v-if="item.guestName">
                                    <span class="textField2"> Reserved for</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.guestName }}
                                    </span> -->
                                    <v-text-field
                                      outlined
                                      v-model="editingitem.guestName"
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>

                                <v-layout wrap py-3>
                                  <v-flex md6 sm6 pa-1 text-left>
                                    <span class="textField2">
                                      No. of persons accompanying</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ item.numberOfAccompanyigPersons }}
                                    </span> -->

                                    <v-text-field
                                      outlined
                                      v-model="
                                        editingitem.numberOfAccompanyigPersons
                                      "
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex md6 sm6 pa-1 text-left>
                                    <span class="textField2">
                                      Guest's Phone no.</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.guestPhone }}
                                    </span> -->
                                    <v-text-field
                                      outlined
                                      v-model="editingitem.guestPhone"
                                      hide-details
                                      type="number"
                                      :rules="[rules.required, rules.min]"
                                      dense
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex md6 sm6 pa-1 text-left>
                                    <span
                                      v-if="item.referee"
                                      class="textField2"
                                    >
                                      Referred by</span
                                    >
                                    <br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.referee }}
                                    </span> -->

                                    <v-text-field
                                      outlined
                                      v-model="editingitem.referee"
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex
                                    md6
                                    sm6
                                    pa-1
                                    text-left
                                    v-if="item.refereePhone"
                                  >
                                    <span class="textField2">
                                      Reference Person's Phone</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.refereePhone }}
                                    </span> -->
                                    <v-text-field
                                      outlined
                                      v-model="editingitem.refereePhone"
                                      hide-details
                                      type="number"
                                      :rules="[rules.required, rules.min]"
                                      dense
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>

                                <v-layout wrap py-3>
                                  <v-flex md6 sm6 pa-1 text-left>
                                    <span class="textField2"> Email</span><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ item.email }}
                                    </span> -->
                                    <v-text-field
                                      outlined
                                      v-model="editingitem.email"
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex
                                    md6
                                    sm6
                                    pa-1
                                    text-left
                                    v-if="foods && editingitem.foodPreference"
                                  >
                                    <span class="textField2">
                                      Food Preference</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px;font-size:12px"
                                      v-for="(items, ii) in item.foodPreference"
                                      :key="ii"
                                    >
                                      {{ items }} <br />
                                    </span> -->

                                    <v-select
                                      outlined
                                      dense
                                      v-model="editingitem.foodPreference"
                                      :items="foods"
                                      multiple
                                      clearable
                                      hide-details
                                      class="text-left"
                                    >
                                    </v-select>
                                  </v-flex>

                                  <v-flex md6 sm6 pa-1 text-left>
                                    <span class="textField2">
                                      No. of Vehicles</span
                                    >
                                    <br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px;"
                                    >
                                      {{ item.numberOfVehicles }}
                                    </span> -->
                                    <v-text-field
                                      outlined
                                      v-model="editingitem.numberOfVehicles"
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex
                                    md6
                                    sm6
                                    pa-1
                                    text-left
                                    v-if="item.vehicleNumbers"
                                  >
                                    <span class="textField2">
                                      Vehicle Numbers</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px;font-size:12px"
                                      v-for="(veh, v) in item.vehicleNumbers"
                                      :key="v"
                                    >
                                      {{ veh }} <br />
                                    </span> -->
                                    <v-combobox
                                      v-model="editingitem.vehicleNumbers"
                                      outlined
                                      :items="keyArray"
                                      multiple
                                      dense
                                      small-chips
                                      hint="Note: Type number and press Enter key"
                                    >
                                      <template
                                        v-slot:selection="{
                                          attrs,
                                          item,
                                          parent,
                                          selected,
                                        }"
                                      >
                                        <span class="pa-1">
                                          <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            small
                                          >
                                            <span
                                              class="pr-2"
                                              style="font-family: opensansregular; font-size: 13px"
                                            >
                                              {{ item }} </span
                                            >&nbsp;
                                            <v-icon
                                              small
                                              @click="parent.selectItem(item)"
                                            >
                                              mdi-close-circle
                                            </v-icon>
                                          </v-chip>
                                        </span>
                                      </template>
                                      <template v-slot:append>
                                        <span></span>
                                      </template>
                                    </v-combobox>
                                  </v-flex>
                                  <v-flex xs12 md6 text-left>
                                    <span class="textField2">
                                      Other details provided</span
                                    ><br />
                                    <!-- <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.details }} <br />
                                    </span> -->
                                    <v-text-field
                                      outlined
                                      v-model="editingitem.details"
                                      hide-details
                                      dense
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                              color="red"
                              text
                              @click="item.editdialog = false"
                              >Cancel</v-btn
                            >

                            <v-btn
                              color="#005f32"
                              text
                              @click="updateIB(), (item.editdialog = false)"
                              >
                              <b>
                                Update
                              </b>
                              </v-btn
                            >
                            <!-- <v-spacer></v-spacer> -->
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs6 lg4 align-self-end text-right>
                      <v-dialog
                        v-model="item.dialog"
                        :max-width="
                          $vuetify.breakpoint.name == 'lg' ||
                          $vuetify.breakpoint.name == 'xl'
                            ? '75%'
                            : '90%'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="warning"
                            style="font-family: poppinsmedium"
                            small
                            text
                            v-on="on"
                            v-bind="attrs"
                            class="text-capitalize"
                          >
                            View more
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            style="font-family:poppinsregular;font-size:13px"
                          >
                            <v-layout wrap justify-center>
                              <v-flex xs4 lg2 align-self-start pt-2>
                                <v-img
                                  v-if="
                                    item.slotDetail.slot.programme.photos
                                      .length > 0
                                  "
                                  contain
                                  height="110px"
                                  :src="
                                    mediaURL +
                                      item.slotDetail.slot.programme.photos[0]
                                  "
                                >
                                  <template v-slot:placeholder>
                                    <ImageLoader />
                                  </template>
                                </v-img>
                                <v-img
                                  v-else
                                  height="113px"
                                  src="require('../../assets/images/no-image.png')"
                                >
                                  <template v-slot:placeholder>
                                    <ImageLoader />
                                  </template>
                                </v-img>
                              </v-flex>
                              <v-flex xs8 lg10 px-2 align-self-start>
                                <v-layout wrap justify-start>
                                  <v-flex xs12 text-left>
                                    <span
                                      class="itemCaption"
                                      style="font-size: 18px"
                                    >
                                      {{
                                        item.slotDetail.slot.programme.progName
                                      }}
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    md6
                                    text-left
                                    py-2
                                    v-if="item.addedBy"
                                  >
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                      >Added By :
                                    </span>
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                    >
                                      {{ item.addedBy.email }}
                                      <span v-if="item.addedBy.details">
                                        <span v-if="item.addedBy.details.name">
                                          ({{ item.addedBy.details.name }})
                                        </span>
                                      </span>
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    md6
                                    text-left
                                    py-2
                                    v-if="item.addedBy"
                                  >
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                      >Phone :
                                    </span>
                                    <span
                                      class="itemCaption"
                                      style="font-size: 16px"
                                    >
                                      {{ item.addedBy.phone }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap py-3>
                                  <v-flex md3 text-left>
                                    <span class="textField2">
                                      Selected Date</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ formatDate(item.bookingDate) }}
                                    </span>
                                  </v-flex>

                                  <v-flex md3 text-left>
                                    <span class="textField2">
                                      Selected Slot</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{
                                        formatTime(item.slotDetail.startTime)
                                      }}
                                      -
                                      {{ formatTime(item.slotDetail.endTime) }}
                                    </span>
                                  </v-flex>

                                  <v-flex md3 text-left>
                                    <span
                                      v-if="
                                        item.slotDetail.slot.programme.isCottage
                                      "
                                      class="textField2"
                                    >
                                      Rooms Reserved</span
                                    >
                                    <span v-else class="textField2">
                                      Seats Reserved</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.reserved }}
                                    </span>
                                  </v-flex>

                                  <v-flex md3 text-left v-if="item.guestName">
                                    <span class="textField2"> Reserved for</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.guestName }}
                                    </span>
                                  </v-flex>
                                </v-layout>

                                <v-layout wrap py-3>
                                  <v-flex md6 sm6 lg3 text-left>
                                    <span class="textField2">
                                      No. of persons accompanying</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ item.numberOfAccompanyigPersons }}
                                    </span>
                                  </v-flex>

                                  <v-flex md6 sm6 lg3 text-left>
                                    <span class="textField2">
                                      Guest's Phone no.</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.guestPhone }}
                                    </span>
                                  </v-flex>

                                  <v-flex md6 sm6 lg3 text-left>
                                    <span
                                      v-if="item.referee"
                                      class="textField2"
                                    >
                                      Referred by</span
                                    >
                                    <br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.referee }}
                                    </span>
                                  </v-flex>

                                  <v-flex
                                    md6
                                    sm6
                                    lg3
                                    text-left
                                    v-if="item.refereePhone"
                                  >
                                    <span class="textField2">
                                      Reference Person's Phone</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.refereePhone }}
                                    </span>
                                  </v-flex>
                                </v-layout>

                                <v-layout wrap py-3>
                                  <v-flex
                                    md6
                                    sm6
                                    lg3
                                    text-left
                                    v-if="item.email"
                                  >
                                    <span class="textField2"> Email</span><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                      >{{ item.email }}
                                    </span>
                                  </v-flex>

                                  <v-flex
                                    md6
                                    sm6
                                    lg3
                                    text-left
                                    v-if="item.foodPreference"
                                  >
                                    <span class="textField2">
                                      Food Preference</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px;font-size:12px"
                                      v-for="(items, ii) in item.foodPreference"
                                      :key="ii"
                                    >
                                      {{ items }} <br />
                                    </span>
                                  </v-flex>

                                  <v-flex md6 sm6 lg3 text-left>
                                    <span
                                      v-if="item.numberOfVehicles"
                                      class="textField2"
                                    >
                                      No. of Vehicles</span
                                    >
                                    <br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px;"
                                    >
                                      {{ item.numberOfVehicles }}
                                    </span>
                                  </v-flex>

                                  <v-flex
                                    md6
                                    sm6
                                    lg3
                                    text-left
                                    v-if="item.vehicleNumbers"
                                  >
                                    <span class="textField2">
                                      Vehicle Numbers</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px;font-size:12px"
                                      v-for="(veh, v) in item.vehicleNumbers"
                                      :key="v"
                                    >
                                      {{ veh }} <br />
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    md6
                                    text-left
                                    v-if="item.details"
                                  >
                                    <span class="textField2">
                                      Other details provided</span
                                    ><br />
                                    <span
                                      class="cardSubHeading"
                                      style="letter-spacing: 0px"
                                    >
                                      {{ item.details }} <br />
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="red" text @click="item.dialog = false"
                              >close</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12 sm6 lg4 align-self-end text-right>
                      <v-btn
                        plain
                        class="text-capitalize"
                        @click="getOrder(item._id)"
                        color="red"
                        >Cancel Reservation</v-btn
                      ></v-flex
                    >
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="itemdelete" max-width="600px">
      <v-card>
        <v-card-title style="font-family:poppinssemibold;font-size:16px"
          >Are you sure you want to delete this Reservation?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="itemdelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem(reservId)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ImageLoader from "@/components/Common/imageLoader";
export default {
  props: ["reservationData", "pages"],
  components: {
    ImageLoader,
  },
  data() {
    return {
      currentPage: 1,
      itemdelete: false,
      reservId: "",
      editingitem: null,

      foods: ["Vegetarian", "Non-Vegetarian", "Vegetarian and Non Vegetarian" ],
      keyArray: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length === 10 || "Max 10 characters",
      },
    };
  },
  watch: {
    currentPage() {
      this.winStepper();
    },
  },
  methods: {
    updateIB() {
      this.appLoading = true;
      this.editingitem.id=this.editingitem._id
      axios({
        method: "POST",
        url: "/reserve/ib/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.editingitem,
      })
        .then((response) => {
          this.appLoading = false;
          this.itemdelete = false;
          if (response.data.status) {
            this.winStepper();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper() {
      console.log("step", this.currentPage);
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.pages,
      });
    },
    getOrder(item) {
      this.reservId = item;
      this.itemdelete = true;
    },
    deleteItem(val) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/booking/ib/adminreservation/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: val,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.itemdelete = false;
          if (response.data.status) {
            this.winStepper();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
